var qs = require('qs');
const nodeURL = process.env.REACT_APP_STRAPI_URL
const headers = () => ({
  'Content-Type': 'application/json',
  Authorization: `Bearer ${localStorage.getItem('accessToken')}`
})


// ********** CREATE WORK ORDER  ********** //
export const createWorkorder = async (details) => {
  const opts = {
    method: 'POST',
    headers: headers(),
    body: JSON.stringify(details),
  }

  const response = await fetch(`${nodeURL}/workorders`, opts);
  const data = response.json();

  return data;
}

// ********** CREATE WORK ORDER  ********** //
export const workOrderSendNotification = async (id, recipients, message, emails) => {
  const opts = {
    method: 'POST',
    headers: headers(),
    body: JSON.stringify({ recipients, message, emails }),
  }

  const response = await fetch(`${nodeURL}/workorders/notify/${id}`, opts);
  const data = response.json();

  return data;
}

// ********** GET ALL WORK ORDERS  ********** //
export const fetchWorkorders = async () => {
  const limit = 999;
  let start = 0;
  let hasMore = true;
  let allWorkorders = [];

  while (hasMore) {
    const opts = {
      method: 'GET',
      headers: headers(),
    };

    const response = await fetch(`${nodeURL}/workorders?status_ne=complete&_limit=${limit}&_start=${start}&_exclude[]=changelogs&_exclude[]=floors&_exclude[]=rooms&_exclude[]=images&_exclude[]=documents`, opts);
    const data = await response.json();

    allWorkorders = [...allWorkorders, ...data];

    if (data.length < limit) {
      hasMore = false;
    } else {
      start += limit;
    }
  }

  return allWorkorders;
};

// ********** GET A WORK ORDER (BY ID)  ********** //
export const fetchAWorkOrder = async (id) => {
  const opts = {
    method: 'GET',
    headers: headers(),
  }

  const response = await fetch(`${nodeURL}/workorders/${id}`, opts);
  const data = response.json();

  return data;
}

// ********** GET A WORK ORDER (BY ID)  ********** //
export const migrateWOActivityLog = async (id) => {
  const opts = {
    method: 'GET',
    headers: headers(),
  }

  const response = await fetch(`${nodeURL}/workorders/restorecl/${id}`, opts);
  console.log(response)
  const data = await response.json();
  console.log(data)

  return data.result;
}

// ********** GET ALL WORK ORDERS BY STATUS  ********** //
export const fetchWorkordersByStatus = async (status, limit = 250, searchTerm = false) => {
  const opts = {
    method: 'GET',
    headers: headers()
  }

  let queryObj = {
    _sort: 'createdAt:DESC',
    _limit: limit,
    _exclude: ['changelogs', 'floors', 'rooms', 'images', 'documents', 'assigned_to_sales', 'assigned_to_pm', 'assigned_to_leadtech'],
    _where: [{ 'status_eq': status }],
  }
  if (searchTerm) {
    queryObj._where.push({
      _or: [
        { 'title_contains': searchTerm },
        { 'client.name_contains': searchTerm },
        { 'location.title_contains': searchTerm }
      ],
    })
  }

  const query = qs.stringify(queryObj);

  // const response = await fetch(`${nodeURL}/workorders/${status}`, opts);
  const response = await fetch(`${nodeURL}/workorders?${query}`, opts);
  const data = response.json();

  return data;
}

// ********** GET ALL WORK ORDERS BY STATUS  ********** //
export const fetchWorkordersForExport = async () => {
  const opts = {
    method: 'GET',
    headers: headers()
  }

  let queryObj = {
    _sort: 'createdAt:DESC',
    _limit: -1,
    _exclude: ['changelogs', 'floors', 'rooms', 'images', 'documents'],
    _where: [{ 'status_in': ['pending', 'scheduled'] }],
  }

  const query = qs.stringify(queryObj);

  const response = await fetch(`${nodeURL}/workorders?${query}`, opts);
  const data = response.json();

  return data;
}

// ********** UPDATE A WORK ORDER (BY ID)  ********** //
export const updateWorkorder = async (id, details) => {
  const opts = {
    method: 'PUT',
    headers: headers(),
    body: JSON.stringify(details)
  }

  const response = await fetch(`${nodeURL}/workorders/${id}`, opts);
  const data = response.json();

  return data;
}

// ********** DELETE A WORK ORDER (BY ID) - DOES NOT ACTUALLY DELETE JUST SETS STATUS AS DELETED  ********** //
export const deleteWorkorder = async (id) => {
  const opts = {
    method: 'PUT',
    headers: headers(),
    body: JSON.stringify({ status: 'deleted' })
  }

  const response = await fetch(`${nodeURL}/workorders/${id}`, opts);
  const data = response.json();

  return data;
}

// ********** UPDATE NOTES IN WORK ORDER (BY ID)  ********** //

export const updateNotes = async (id, details) => {
  const opts = {
    method: 'PUT',
    headers: headers(),
    body: JSON.stringify(details)
  }

  const response = await fetch(`${nodeURL}/workorders/${id}`, opts);
  const data = response.json();

  return data;
}

export const fetchWorkordersById = async (workdorderIds) => {
  const opts = {
    method: "GET",
    headers: headers(),
  };
  let url = `${nodeURL}/workorders`
  const params = new URLSearchParams()
  workdorderIds.forEach((id) => {
    params.append("id_in", id)
  })
  const response = await fetch(url + "?" + params.toString(), opts).then(data => data.json());
  return response;
}

export const fetchWorkordersByUserId = async (id) => {
  const opts = {
    method: 'GET',
    headers: headers(),
  };

  let queryObj = {
    _where: [{
      _or: [
        { "assigned_to": id },
        { "assigned_to_sales": id },
        { "assigned_to_pm": id },
        { "assigned_to_leadtech": id }
      ],
    }],
  }

  const query = qs.stringify(queryObj);

  const response = await fetch(`${nodeURL}/workorders?${query}`, opts).then(data => data.json());

  return response;
}
