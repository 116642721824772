const nodeURL = process.env.REACT_APP_STRAPI_URL
const headers = () => ({
    "Content-Type": "application/json",
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`
})

// ********** CREATE TIME ENTRY  ********** //
export const createTimeEntry = async (values) => {
    const opts = {
        method: 'POST',
        headers: headers(),
        body: JSON.stringify(values),
    }

    const response = await fetch(`${nodeURL}/time-entries`, opts);
    const data = response.json();

    return data;
}

// ********** CREATE TIME ENTRY  ********** //
export const updateTimeEntry = async (id, values) => {
    const opts = {
        method: 'PUT',
        headers: headers(),
        body: JSON.stringify(values),
    }

    const response = await fetch(`${nodeURL}/time-entries/${id}`, opts);
    const data = response.json();

    return data;
}

// ********** GET ALL TIME ENTRIES  ********** //
export const fetchTimeEntriesForWorkorder = async (id) => {
    const opts = {
        method: 'GET',
        headers: headers(),
    }

    const response = await fetch(`${nodeURL}/time-entries?workorder.id=${id}`, opts);
    const data = response.json();

    return data;
}

export const deleteTimeEntry = async (id) => {
    const opts = {
        method: 'DELETE',
        headers: headers()
    }

    const response = await fetch(`${nodeURL}/time-entries/${id}`, opts);
    const data = response.json();

    return data;
}