const nodeURL = process.env.REACT_APP_STRAPI_URL
const headers = () => ({
  "Content-Type": "application/json",
  Authorization: `Bearer ${localStorage.getItem("accessToken")}`
})

// ********** CREATE PRODUCT  ********** //
export const createProduct = async (details) => {
  const opts = {
    method: 'POST',
    headers: headers(),
    body: JSON.stringify(details),
  }

  const response = await fetch(`${nodeURL}/products`, opts);
  const data = response.json();

  return data;
}

// ********** GET ALL PRODUCTS  ********** //
export const fetchProducts = async () => {
  const opts = {
    method: 'GET',
    headers: headers(),
  }

  const response = await fetch(`${nodeURL}/products`, opts);
  const data = response.json();

  return data;
}

// ********** GET A PRODUCT (BY ID)  ********** //
export const fetchProduct = async (id) => {
  const opts = {
    method: 'GET',
    headers: headers(),
  }

  const response = await fetch(`${nodeURL}/products/${id}`, opts);
  const data = response.json();
  return data;
}

// ********** UPDATE A PRODUCT (BY ID)  ********** //
export const updateProduct = async (id, details) => {
  const opts = {
    method: 'PUT',
    headers: headers(),
    body: JSON.stringify(details)
  }

  const response = await fetch(`${nodeURL}/products/${id}`, opts);
  const data = response.json();

  return data;
}

// ********** DELETE A PRODUCT (BY ID)  ********** //
export const deleteProduct = async (id) => {
  const opts = {
    method: 'DELETE',
    headers: headers(),
  }

  const response = await fetch(`${nodeURL}/products/${id}`, opts);
  const data = response.json();

  return data;
}

// ********** DELETE A PRODUCT (BY ID)  ********** //
export const fetchAllProductsForWorkorder = async (id) => {
  const opts = {
    method: 'GET',
    headers: headers(),
  }

  const response = await fetch(`${nodeURL}/products?workorder.id=${id}`, opts);
  const data = response.json();

  console.log({ data });

  return data;
}

export const fetchInventories = async () => {
  const opts = {
    method: 'GET',
    headers: headers(),
  }

  const response = await fetch(`${nodeURL}/inventories?_limit=200`, opts);
  const data = response

  console.log({ data });

  return data;
}

export const createInventories = async (details) => {
  const opts = {
    method: 'POST',
    headers: headers(),
    body: JSON.stringify(details)
  }

  const response = await fetch(`${nodeURL}/inventories`, opts)

  return response
}

export const searchInventories = async (query) => {
  const opts = {
    method: 'GET',
    headers: headers(),
  }

  const response1 = await fetch(`${nodeURL}/inventories?fullname_contains=${query}&_limit=100`, opts);
  const response2 = await fetch(`${nodeURL}/inventories?description_contains=${query}&_limit=100`, opts);
  const data1 = await response1.json()
  const data2 = await response2.json()

  return [...data1, ...data2];
}