import React from 'react';
import { Button, Box, Container, Typography, withStyles, Dialog, DialogTitle, DialogContent, DialogContentText } from '@material-ui/core';

// ********** STYLES ********** //
const styles = theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh'
  },
});

// ********** COMPONENT ********** //
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null, showDetails: false };
  };

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo
    })
    // You can also log error messages to an error reporting service here
  };

  render() {
    const { classes } = this.props;

    if (this.state.errorInfo) {
      // Error path
      return (
        <Container className={classes.root}>
          <Box>
            <Typography variant='h4'>An error occurred. Click Show Details to see the technical error message.</Typography>
            <Typography variant='h4'>
              <a href="/app/dashboard">Navigate back to the dashboard</a>
            </Typography>
            <Button style={{ marginTop: 30 }} onClick={() => this.setState({ showDetails: true })}>Show Details</Button>
          </Box>
          <Dialog open={this.state.showDetails} onClose={() => this.setState({ showDetails: false })}>
            <DialogTitle>Error Details (copy and paste into bug report)</DialogTitle>
            <DialogContent>
              <DialogContentText>
                URL: {window.location.href}
                <br />
                Error Message: {this.state.error && this.state.error.toString()}
                <br />
                Error Trace: {this.state.errorInfo.componentStack}
              </DialogContentText>
            </DialogContent>
          </Dialog>
        </Container>
      );
    }
    // Normally, just render children
    return this.props.children;
  }
}

// HOC to use MaterialUI makeStyles/withStyles hook with ErrorBoundary class component(required)
export default withStyles(styles)(ErrorBoundary);