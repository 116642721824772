import { combineReducers } from '@reduxjs/toolkit';
import { reducer as calendarReducer } from 'src/slices/calendar';
import { reducer as chatReducer } from 'src/slices/chat';
import { reducer as formReducer } from 'redux-form';
import { reducer as mailReducer } from 'src/slices/mail';
import { reducer as notificationReducer } from 'src/slices/notification';
// import { reducer as floorReducer } from 'src/slices/floor';
import { reducer as floorReducer } from 'src/slices/floor';


const rootReducer = combineReducers({
  calendar: calendarReducer,
  // floor: floorReducer,
  floor: floorReducer,
  // chat: chatReducer,
  // form: formReducer,
  // mail: mailReducer,
  // notifications: notificationReducer
});

export default rootReducer;
