/* eslint-disable no-use-before-define */
import React, { useEffect, useState } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Chip,
  Divider,
  Drawer,
  Hidden,
  Link,
  List,
  ListSubheader,
  Typography,
  makeStyles
} from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import WorkIcon from '@material-ui/icons/Work';
import DateRangeIcon from '@material-ui/icons/DateRange';
import PersonPinRoundedIcon from '@material-ui/icons/PersonPinRounded';
import PersonIcon from '@material-ui/icons/Person';
import SettingsIcon from '@material-ui/icons/Settings';
import LogoutIcon from '@material-ui/icons/ExitToApp';
import Logo from 'src/components/Logo';
import useAuth from 'src/hooks/useAuth';
import NavItem from './NavItem';
import clsx from 'clsx';
import { ArrowLeft, ArrowRight, BugReport } from '@material-ui/icons';
import { getLargestImage } from 'src/utils/getLargestImage';

const nodeURL = process.env.REACT_APP_STRAPI_URL

const miniDrawerWidth = 96;
const openDrawerWidth = 256

const sections = [
  {
    subheader: '',
    items: [
      {
        title: 'Home',
        icon: HomeIcon,
        href: '/app/dashboard',
        managerOnly: false
      },
      {
        title: 'Work Orders',
        icon: WorkIcon,
        href: '/app/workorders',
        managerOnly: false
      },
      {
        title: 'Schedule',
        icon: DateRangeIcon,
        href: '/app/schedule',
        managerOnly: false
      },
      {
        title: 'Clients',
        icon: PersonPinRoundedIcon,
        href: '/app/clients',
        managerOnly: false
      },
      {
        title: 'Team',
        icon: PersonIcon,
        href: '/app/technicians',
        managerOnly: false
      }
    ]
  }
];

function renderNavItems({ user, items, pathname, depth = 0, showLabels }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ user, acc, item, pathname, depth, showLabels }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ user, acc, pathname, item, depth, showLabels }) {
  if (item.managerOnly === true && user.role.name !== 'Manager') return acc;

  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    acc.push(
      <NavItem
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center'
        }}
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        showLabels={showLabels}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          user: user,
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center'
        }}
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        showLabels={showLabels}
        title={item.title}
      />
    );
  }

  return acc;
}

// ********** STYLES  ********** //
const useStyles = makeStyles(theme => ({
  navBar: {
    width: miniDrawerWidth
  },
  navBarBg: {
    backgroundColor: theme.palette.background.paper
  },
  mobileDrawerOpen: {
    width: openDrawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    })
  },
  mobileDrawerMini: {
    width: miniDrawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    })
  },
  desktopDrawerOpen: {
    width: openDrawerWidth,
    backgroundColor: '#F6F6F6',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    })
  },
  desktopDrawerMini: {
    width: miniDrawerWidth,
    backgroundColor: '#F6F6F6',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    })
  },
  userDisplay: {
    display: 'flex',
    flexDirection: 'column',
    // backgroundColor: theme.palette.abeGreen.main,
    height: 200
  },
  userNameBox: {
    marginTop: 'auto'
  },
  avatar: {
    cursor: 'pointer',
    width: 50,
    height: 50
  },
  name: {
    fontSize: '1.6rem'
  },
  position: {
    textTransform: 'capitalize'
  },
  settings: {
    // position: 'absolute',
    // bottom: '25px',
    padding: theme.spacing(2),
    width: '100%'
  },
  mainNavItemList: {
    // padding: theme.spacing(2),
    width: '100%'
  },
  desktopImpersonating: {
    marginTop: 64
  }
}));
let loadedState = localStorage.getItem("navStatus")
console.log(loadedState)

// ********** COMPONENT  ********** //
const NavBar = () => {
  const [isMiniNav, setIsMiniNav] = useState(loadedState == "true" ? true : false);
  const [showLabels, setShowLabels] = useState(loadedState == "true" ? false : true);
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const { user, logout, impersonating } = useAuth();
  let labelDelayTimeout

  // useEffect(() => {
  //   if (isMiniNav && setIsMiniNav) {
  //     setShowLabels(false)
  //     setIsMiniNav(true);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [location.pathname]);

  // useEffect(() => {
  //   setIsMiniNav(loadedState)
  //   setShowLabels(!loadedState)
  // }, [])

  const content = (
    <Box className={classes.navBarBg} height="100%" display="flex" flexDirection="column" justifyContent="space-between">
      {/* <PerfectScrollbar options={{ suppressScrollX: true }}> */}
      <Box className={clsx(classes.navBarSection, classes.userDisplay)} pt={4} pr={2} pb={2} pl={2} display="flex" flexDirection="column" alignItems={isMiniNav ? "center" : "center"}>
        <Box display="flex" justifyContent="left">
          <RouterLink to="/app/account">
            <Avatar
              // alt={getInitials(user.name)}
              className={classes.avatar}
              src={user?.avatar && user?.avatar.formats ? `${getLargestImage(user.avatar, 'thumbnail')}` : ""}
            />
          </RouterLink>
        </Box>
        {showLabels && user && (<Box className={classes.userNameBox} pl={1} pr={1} textAlign="left">
          <Link
            className={classes.name}
            component={RouterLink}
            to="/app/account"
            variant="h5"
            // color="textSecondary"
            underline="none"
          >
            {user && user.name ? user.name : 'N/A'}
          </Link>
          <Typography
            variant="body2"
            // color="textSecondary"
            className={classes.position}
          >
            {user.role.name}
          </Typography>
        </Box>)}
      </Box>
      <Box className={classes.navBarSection} p={2} display="flex" flexDirection="column" alignItems={isMiniNav ? "center" : "flex-start"}>
        {sections.map(section => (
          <List
            key={section.subheader}
            subheader={
              <ListSubheader disableGutters disableSticky>
                {section.subheader}
              </ListSubheader>
            }
            className={classes.mainNavItemList}
          >
            {renderNavItems({
              user: user,
              items: section.items,
              pathname: location.pathname,
              showLabels: showLabels
            })}
          </List>
        ))}
      </Box>
      <Box className={clsx(classes.navBarSection, classes.settings)} display="flex" flexDirection="column" alignItems={isMiniNav ? "center" : "flex-start"}>
        <NavItem
          title="Settings"
          icon={SettingsIcon}
          href="/app/account"
          showLabels={showLabels}
        // className={classes.settings}
        />
        <NavItem
          title="Bug Report"
          icon={BugReport}
          href="/bug-report"
          showLabels={showLabels}
        />
        <NavItem
          title="Logout"
          icon={LogoutIcon}
          href="/app"
          showLabels={showLabels}
          onClick={() => {
            const isConfirmed = window.confirm('Confirm to log out.');
            if (isConfirmed) {
              const response = logout();
              if (response) history.push('/auth/login');
            }
          }}
        />
        <NavItem
          title="Collapse Menu"
          icon={isMiniNav ? ArrowRight : ArrowLeft}
          href="#"
          showLabels={showLabels}
          onClick={() => {
            localStorage.setItem("navStatus", !(isMiniNav))
            setIsMiniNav(!isMiniNav)
            setTimeout(() => window.dispatchEvent(new Event('resize')), 250)
            setTimeout(() => window.dispatchEvent(new Event('resize')), 500)
            if (isMiniNav) {
              labelDelayTimeout = setTimeout(() => setShowLabels(true), 250)
            } else {
              setShowLabels(false)
              clearTimeout(labelDelayTimeout)
              setTimeout(() => setShowLabels(false), 250)
            }
          }}
        />
      </Box>
      {/* </PerfectScrollbar> */}
    </Box>
  );

  return (
    <Drawer
      anchor="left"
      className={clsx(classes.navBar, {
        [classes.desktopDrawerMini]: isMiniNav,
        [classes.desktopDrawerOpen]: !isMiniNav,
        [classes.desktopImpersonating]: impersonating
      })}
      classes={{
        paper: clsx({
          [classes.desktopDrawerMini]: isMiniNav,
          [classes.desktopDrawerOpen]: !isMiniNav,
          [classes.desktopImpersonating]: impersonating
        }),
      }}
      open
      variant="persistent"
    >
      {content}
    </Drawer>
  );
};

NavBar.propTypes = {
  setIsMiniNav: PropTypes.func,
  isMiniNav: PropTypes.bool
};

export default NavBar;
