import React from 'react';
import PropTypes from 'prop-types';
import { Container, createMuiTheme, ThemeProvider } from '@material-ui/core';



const PrintLayout = ({ children }) => {

  return (
    <ThemeProvider theme={(theme) => createMuiTheme({
      ...theme,
      breakpoints: {
        values: {
          xs: 0
        }
      },
      overrides: {
        MuiTableCell: {
          sizeSmall: {
            fontSize: '0.75rem',
            paddingLeft: '0.1in',
            paddingRight: '0.1in',
            paddingBottom: '0.05in',
            paddingTop: '0.05in'
          }
        }
      }
    })}>
      <Container fixed>
        {children}
      </Container>
    </ThemeProvider>
  );
};

PrintLayout.propTypes = {
  children: PropTypes.node
};

export default PrintLayout;
