import { createSlice } from '@reduxjs/toolkit';
import objFromArray from '../utils/objFromArray';
import { fetchFloorRooms, updateFloor } from '../actions/floorActions';
import { updateRoom } from '../actions/roomActions';

const initialState = {
    isLoaded: false,
    rooms: {
        byId: {},
        allIds: []
    },
    workitems: {
        byId: {},
        allIds: []
    }
};

const slice = createSlice({
    name: 'room',
    initialState,
    reducers: {
        getFloorRoomObjects(state, action) {
            const { floorRooms } = action.payload;

            state.rooms.byId = objFromArray(floorRooms);
            state.rooms.allIds = floorRooms.map(room => room.id);

            const workitems = floorRooms.reduce((acc, room) => {
                return [...acc, ...room.workitems];
            }, []);

            state.workitems.byId = objFromArray(workitems);
            state.workitems.allIds = workitems.map(workitem => workitem.id);

            state.isLoaded = true;
        },
        createRoom(state, action) {
            const room = action.payload;

            state.rooms.byId[room.id] = room;
            state.rooms.allIds.push(room.id);
        },
        moveRoom(state, action) {
            const { currentFloor, roomId, newPosition } = action.payload;

            // TODO: Update the position of the room

            let newRoomOrder = state.rooms.allIds.filter(_listId => _listId !== roomId)
            newRoomOrder.splice(newPosition, 0, roomId)
            state.rooms.allIds = newRoomOrder

            const floorValues = {
                rooms: state.rooms.allIds
            };

            updateFloor(currentFloor.id, floorValues);
        },
        // updateRoom(state, action) {
        //     const room = action.payload;

        //     state.rooms.byId[room.id] = room;
        // },
        clearRoom(state, action) {
            const roomId = action.payload;

            // workitemIds to be removed
            const { workitemIds } = state.rooms.byId[roomId];

            // Delete the workitemIds references from the room
            state.rooms.byId[roomId].workitemIds = [];

            // Delete the workitems from state
            workitemIds.forEach((workitemId) => {
                delete state.workitems.byId[workitemId];
            });

            state.workitems.allIds = state.workitems.allIds.filter((workitemId) => workitemIds.includes(workitemId));
        },
        deleteRoomState(state, action) {
            const roomId = action.payload;

            delete state.rooms.byId[roomId];
            state.rooms.allIds = state.rooms.allIds.filter((_listId) => _listId !== roomId);
        },
        createWorkItem(state, action) {
            const workitem = action.payload;

            state.workitems.byId[workitem.id] = workitem;
            state.workitems.allIds.push(workitem.id);

            // Add the workitemId reference to the room
            state.rooms.byId[workitem.roomId].workitemIds.push(workitem.id);
        },
        updateWorkItem(state, action) {
            const workitem = action.payload;

            Object.assign(state.workitems.byId[workitem.id], workitem);
        },
        moveWorkItem(state, action) {
            const { workitemId, position, sourceRoomId, roomId } = action.payload;

            // Set source room as a const for updating later
            const sourceRoom = state.rooms.byId[sourceRoomId];

            // Remove workitem from source room
            state.rooms.byId[sourceRoomId].workitems = (state.rooms.byId[sourceRoomId].workitems.filter((workitems) => workitems.id !== workitemId));

            // If roomId exists, it means that we have to add the workitem to the new room
            if (roomId) {
                // Set new room as a const for updating later
                const newRoom = state.rooms.byId[roomId];
                // Change workitem's roomId reference
                state.workitems.byId[workitemId].room = roomId;
                // Push the workitemId to the specified position in the new room
                state.rooms.byId[roomId].workitems.splice(position, 0, state.workitems.byId[workitemId]);
                // Update the new room
                const updatedNewRoomValues = {
                    workitems: state.rooms.byId[roomId].workitems
                };
                updateRoom(newRoom.id, updatedNewRoomValues);
            } else {
                // Push the workitemId to the specified position in the source room
                state.rooms.byId[sourceRoomId].workitems.splice(position, 0, state.workitems.byId[workitemId]);
            }
            // Update the source room
            const updatedSourceRoomValues = {
                workitems: state.rooms.byId[sourceRoomId].workitems
            };
            updateRoom(sourceRoom.id, updatedSourceRoomValues);
        },
        deleteWorkItem(state, action) {
            const workitemId = action.payload;
            const { roomId } = state.workitems.byId[workitemId];

            delete state.workitems.byId[workitemId];
            state.workitems.allIds = state.workitems.allIds.filter((_workitemId) => _workitemId !== workitemId);
            state.rooms.byId[roomId].workitemIds = (state.rooms.byId[roomId].workitemIds.filter((_workitemId) => _workitemId !== workitemId));
        },
    }
});

export const { reducer } = slice;

export const getFloorRoomObjects = (floorId) => async (dispatch) => {
    console.log('refetching order of workitems')
    const floorRooms = await fetchFloorRooms(floorId)

    dispatch(slice.actions.getFloorRoomObjects({ floorRooms }))
}

// export const createRoom = (name) => async (dispatch) => {
//     const data = await kanbanApi.createRoom({ name });

//     dispatch(slice.actions.createRoom(data));
// };

export const moveRoom = (currentFloor, roomId, newPosition) => async (dispatch) => {

    dispatch(
        slice.actions.moveRoom({
            currentFloor,
            roomId,
            newPosition
        })
    )

    // const response = await updateUser(userId, {
    // 	channelorder: filteredChannelIdsArray
    // })
}

// export const updateRoom = (roomId, update) => async (dispatch) => {
//     const data = await kanbanApi.updateRoom({ roomId, update });

//     dispatch(slice.actions.updateRoom(data));
// };

// export const clearRoom = (roomId) => async (dispatch) => {
//     await kanbanApi.clearRoom(roomId);

//     dispatch(slice.actions.clearRoom(roomId));
// };

export const deleteRoomState = (roomId) => async (dispatch) => {

    dispatch(slice.actions.deleteRoomState(roomId));
};

// export const createWorkItem = (roomId, name) => async (dispatch) => {
//     const data = await kanbanApi.createWorkItem({ roomId, name });

//     dispatch(slice.actions.createWorkItem(data));
// };

// export const updateWorkItem = (workitemId, update) => async (dispatch) => {
//     const data = await kanbanApi.updateWorkItem({ workitemId, update });

//     dispatch(slice.actions.updateWorkItem(data));
// };

export const moveWorkItem = (workitemId, position, sourceRoomId, roomId) => async (dispatch) => {

    dispatch(slice.actions.moveWorkItem({
        workitemId,
        position,
        sourceRoomId,
        roomId
    }));
};

// export const deleteWorkItem = (workitemId) => async (dispatch) => {
//     await kanbanApi.deleteWorkItem(workitemId);

//     dispatch(slice.actions.deleteWorkItem(workitemId));
// };

export default slice;