const nodeURL = process.env.REACT_APP_STRAPI_URL
const headers = () => ({
  "Content-Type": "application/json",
  Authorization: `Bearer ${localStorage.getItem("accessToken")}`
})

// ********** CREATE WORK ITEM  ********** //
export const createWorkitem = async (details) => {
  const opts = {
    method: 'POST',
    headers: headers(),
    body: JSON.stringify(details),
  }

  const response = await fetch(`${nodeURL}/workitems`, opts);
  const data = response.json();

  return data;
}

// ********** GET ALL WORK ITEMS  ********** //
export const fetchWorkitems = async () => {
  const opts = {
    method: 'GET',
    headers: headers(),
  }

  const response = await fetch(`${nodeURL}/workitems`, opts);
  const data = response.json();

  return data;
}

// ********** GET A WORK ITEM (BY ID)  ********** //
export const fetchWorkitem = async (id) => {
  const opts = {
    method: 'GET',
    headers: headers(),
  }

  const response = await fetch(`${nodeURL}/workitems/${id}`, opts);
  const data = response.json();
  return data;
}

// ********** UPDATE A WORK ITEM (BY ID)  ********** //
export const updateWorkitem = async (id, details) => {
  const opts = {
    method: 'PUT',
    headers: headers(),
    body: JSON.stringify(details)
  }

  const response = await fetch(`${nodeURL}/workitems/${id}`, opts);
  const data = response.json();

  return data;
}

// ********** DELETE A WORK ITEM (BY ID)  ********** //
export const deleteWorkitem = async (id) => {
  const opts = {
    method: 'DELETE',
    headers: headers(),
  }

  const response = await fetch(`${nodeURL}/workitems/${id}`, opts);
  const data = response.json();

  return data;
}