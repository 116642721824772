export const getLargestImage = (object, maxSize) => {
    if (!object || !object.formats) return '/uploads/default_avatar_image_large_ec9e2c2f43.jpeg'

    const { formats } = object
    const formatNames = ['thumbnail', 'small', 'medium', 'large']
    let formatIndex = formatNames.indexOf(maxSize)
    if (formatIndex == -1) {
        return console.log("You've used an invalid image format")
    }

    while (formats[formatNames[formatIndex]] == undefined) {
        formatIndex--
    }

    return formats[formatNames[formatIndex]].url
}