import React from 'react';

const Logo = (props) => {
  const { height } = props;

  return (
    <img
      alt="Logo"
      src="https://www.abenetworks.com/wp-content/uploads/2015/11/abe-networks.png"
      style={{ width: 'auto', height: height }}
      {...props}
    />
  );
}

export default Logo;
