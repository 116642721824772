import React, { Suspense, Fragment, lazy } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';
import HomeView from 'src/views/home/HomeView';
import LoadingScreen from 'src/components/LoadingScreen';
import AuthGuard from 'src/components/AuthGuard';
import GuestGuard from 'src/components/GuestGuard';
import ManagerGuard from 'src/components/ManagerGuard';
import { WorkOrderProvider } from 'src/contexts/WorkOrderContext';
import PrintLayout from './layouts/PrintLayout';

export const renderRoutes = (routes = []) => {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Switch>
        {routes.map((route, i) => {
          const Guard = route.guard || Fragment;
          const Layout = route.layout || Fragment;
          const Component = route.component;
          const RouteContextProvider = route.provider || Fragment

          return (
            <Route
              key={i}
              path={route.path}
              exact={route.exact}
              render={props => (
                <Guard>
                  <RouteContextProvider>
                    <Layout>
                      {route.routes ? (
                        renderRoutes(route.routes)
                      ) : (
                        <Component {...props} />
                      )}
                    </Layout>
                  </RouteContextProvider>
                </Guard>
              )}
            />
          );
        })}
      </Switch>
    </Suspense>
  )
};

const routes = [
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/errors/NotFoundView'))
  },
  {
    exact: true,
    path: '/bug-report',
    component: lazy(() => import('src/views/external/BugReport'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/auth/microsoft/callback',
    component: lazy(() => import('src/views/auth/MicrosoftCallback'))
  },
  {
    exact: true,
    path: '/auth/microsoft/callback/mobile',
    component: lazy(() => import('src/views/auth/MicrosoftCallbackMobile'))
  },
  {
    exact: true,
    path: '/login-unprotected',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/register',
    component: lazy(() => import('src/views/auth/RegisterView'))
  },
  {
    exact: true,
    path: '/register-unprotected',
    component: lazy(() => import('src/views/auth/RegisterView'))
  },
  {
    exact: true,
    path: '/reset-password',
    component: lazy(() => import('src/views/auth/ResetPasswordView'))
  },
  {
    path: '/print',
    guard: AuthGuard,
    layout: PrintLayout,
    routes: [
      {
        exact: true,
        path: '/print/workorder/:workOrderId',
        provider: WorkOrderProvider,
        component: lazy(() => import('src/views/workorder/PrintView')),
      }
    ]
  },
  {
    path: '/app',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/app/account',
        component: lazy(() => import('src/views/account/AccountView'))
      },
      {
        exact: true,
        path: '/app/workorders',
        component: lazy(() => import('src/views/workorder/ListView'))
        // guard: ManagerGuard
      },
      {
        exact: true,
        path: '/app/workorders/create',
        component: lazy(() => import('src/views/workorder/CreateView'))
        // guard: ManagerGuard
      },
      {
        exact: true,
        path: '/app/workorders-draft/:workOrderId',
        provider: WorkOrderProvider,
        component: lazy(() => import('src/views/workorder/DraftView')),
        guard: ManagerGuard
      },
      {
        exact: true,
        path: '/app/workorders/:workOrderId',
        provider: WorkOrderProvider,
        component: lazy(() => import('src/views/workorder/DetailsView')),
        // guard: ManagerGuard
      },
      {
        exact: true,
        path: '/app/schedule',
        component: lazy(() => import('src/views/schedule/ScheduleView'))
      },
      {
        exact: true,
        path: '/app/clients',
        component: lazy(() => import('src/views/client/ListView'))
        // guard: ManagerGuard
      },
      {
        exact: true,
        path: '/app/clients/create',
        component: lazy(() => import('src/views/client/CreateView'))
        // guard: ManagerGuard
      },
      {
        exact: true,
        path: '/app/clients/:clientId',
        component: lazy(() => import('src/views/client/DetailsView'))
        // guard: ManagerGuard
      },
      {
        exact: true,
        path: '/app/clients/:clientId/locations/:locationId',
        component: lazy(() => import('src/views/client/DetailsView/Locations/DetailsView'))
        // guard: ManagerGuard
      },
      {
        exact: true,
        path: '/app/technicians',
        component: lazy(() => import('src/views/technicians/ListView'))
        // guard: ManagerGuard
      },
      {
        exact: true,
        path: '/app/technicians/create',
        component: lazy(() => import('src/views/technicians/CreateView'))
        // guard: ManagerGuard
      },
      {
        exact: true,
        path: '/app/technicians/:techniciansId',
        component: lazy(() => import('src/views/technicians/DetailsView'))
        // guard: ManagerGuard
      },
      {
        exact: true,
        path: '/app/technicians-edit/:techniciansId',
        component: lazy(() => import('src/views/technicians/EditView'))
        // guard: ManagerGuard
      },
      {
        exact: true,
        path: '/app/dashboard/',
        component: lazy(() => import('src/views/dashboard'))
      },
      {
        exact: true,
        path: '/app',
        component: () => <Redirect to="/app/dashboard" />
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  },
  {
    path: '*',
    layout: MainLayout,
    routes: [
      {
        exact: true,
        path: '/',
        component: () => <Redirect to="/login" />
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  }
];

export default routes;
