const nodeURL = process.env.REACT_APP_STRAPI_URL
const headers = () => ({
  "Content-Type": "application/json",
  Authorization: `Bearer ${localStorage.getItem("accessToken")}`
})
// ********** CREATE FLOOR  ********** //
export const createFloor = async (details) => {
  const opts = {
    method: 'POST',
    headers: headers(),
    body: JSON.stringify(details),
  }

  const response = await fetch(`${nodeURL}/floors`, opts);
  const data = response.json();

  return data;
}

// ********** GET FLOORS  ********** //
export const fetchFloors = async () => {
  const opts = {
    method: 'GET',
    headers: headers()
  }

  const response = await fetch(`${nodeURL}/floors`, opts);
  const data = response.json();

  return data;
}


// ********** GET FLOOR OPTIONS (for autocomplete menu)  ********** //
export const fetchFloorOptions = async () => {
  const opts = {
    method: 'GET',
    headers: headers()
  }

  const response = await fetch(`${nodeURL}/flooroptions`, opts);
  const data = response.json();

  return data;
}

// ********** GET A FLOOR (BY ID) ********** //
export const fetchFloor = async (id) => {
  const opts = {
    method: 'GET',
    headers: headers()
  }

  const response = await fetch(`${nodeURL}/floors/${id}`, opts);
  const data = response.json();

  return data;
}
// ********** GET ALL THE ROOMS ON A FLOOR (BY FLOOR ID) ********** //
export const fetchFloorRooms = async (floorId) => {
  const opts = {
    method: 'GET',
    headers: headers()
  }

  const response = await fetch(`${nodeURL}/floors/${floorId}/rooms`, opts);
  const data = response.json();

  return data;
}

// ********** UPDATE A FLOOR (BY ID)  ********** //
export const updateFloor = async (id, details) => {
  const opts = {
    method: 'PUT',
    headers: headers(),
    body: JSON.stringify(details)
  }

  const response = await fetch(`${nodeURL}/floors/${id}`, opts);
  const data = response.json();

  return data;
}

// ********** DELETE A FLOOR (BY ID)  ********** //
export const deleteFloor = async (id) => {
  const opts = {
    method: 'DELETE',
    headers: headers(),
  }

  const response = await fetch(`${nodeURL}/floors/${id}`, opts);
  const data = response.json();

  return data;
}