const nodeURL = process.env.REACT_APP_STRAPI_URL
const headers = () => ({
  "Content-Type": "application/json",
  Authorization: `Bearer ${localStorage.getItem("accessToken")}`
})

// ********** CREATE USER ********** //
export const createUser = async details => {
  details.username = details.email;

  const opts = {
    method: 'POST',
    headers: headers(),
    body: JSON.stringify(details)
  };

  const response = await fetch(`${nodeURL}/users`, opts).then(data => data.json());

  return response;
};

// ********** FETCH ALLOWED ROLES ********** //
export const fetchAllowedRoles = async () => {
  const opts = {
    method: 'GET',
    headers: headers()
  };

  const response = await fetch(`${nodeURL}/users/allowed-roles`, opts).then(data =>
    data.json()
  );

  return response.filteredRoles;
};

// ********** FETCH ALL USERS ********** //
export const fetchUsers = async () => {
  const opts = {
    method: 'GET',
    headers: headers()
  };

  const response = await fetch(`${nodeURL}/users/?_sort=name&_exclude[]=workorders&_exclude[]=time_entries`, opts).then(data =>
    data.json()
  );

  return response;
};

// ********** FETCH A USER (BY ID) ********** //
export const fetchUser = async id => {
  const opts = {
    method: 'GET',
    headers: headers()
  };

  const response = await fetch(`${nodeURL}/users/${id}`, opts).then(data =>
    data.json()
  );

  return response;
};

// ********** UPDATE USER (BY ID) ********** //
export const updateUser = async (id, details) => {
  const opts = {
    method: 'PUT',
    headers: headers(),
    body: JSON.stringify(details)
  };

  const response = await fetch(`${nodeURL}/users/${id}`, opts).then(data =>
    data.json()
  );

  return response;
};

// ********** DELETE USER (BY ID) ********** //
export const deleteUser = ({ id }) => { };

// ********** GET ALL USER ROLES  ********** //
export const fetchUserRoles = async () => {
  const opts = {
    method: 'GET',
    headers: headers()
  };

  const response = await fetch(`${nodeURL}/users-permissions/roles`, opts);
  const data = response.json();

  return data;
};

// ********** GET USER ROLE BY ID  ********** //
export const fetchUserRole = async id => {
  const opts = {
    method: 'GET',
    headers: headers()
  };

  const response = await fetch(
    `${nodeURL}/users-permissions/roles/${id}`,
    opts
  );
  const data = response.json();

  return data;
};

// ********** GET ALL USERS -> TECHNICIANS (LEAD & TECHNICIAN)  ********** //
export const fetchAllUsers = async () => {
  const opts = {
    method: 'GET',
    headers: headers()
  };

  const response = await fetch(
    `${nodeURL}/users?_sort=name:ASC&_exclude[]=workorders&_exclude[]=time_entries`,
    opts
  );
  const data = response.json();

  return data;
};
