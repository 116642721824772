import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import NavBar from './NavBar';
import useAuth from 'src/hooks/useAuth';
import ImpersonatingAppBar from './ImpersonatingAppBar';
// import TopBar from './TopBar';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    // margin: theme.spacing(0, 6, 6, 6),
    margin: theme.spacing(0, 0),
    [theme.breakpoints.up('lg')]: {
      // paddingLeft: 256
    },
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto',
  }
}));

const DashboardLayout = ({ children }) => {
  const classes = useStyles();
  const { impersonating } = useAuth()

  return (<>
    {impersonating && (<ImpersonatingAppBar />)}
    <div className={classes.root}>
      <NavBar />
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>{children}</div>
        </div>
      </div>
    </div>
  </>
  );
};

DashboardLayout.propTypes = {
  children: PropTypes.node
};

export default DashboardLayout;
