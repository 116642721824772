import React, { createContext, useEffect, useState, useReducer } from 'react';
import _ from 'lodash';
import SplashScreen from 'src/components/SplashScreen';
import { fetchAWorkOrder, updateWorkorder } from 'src/actions/workorderActions';
import { useLocation } from 'react-router-dom';
import { createTimeEntry, deleteTimeEntry, updateTimeEntry } from 'src/actions/timeentryActions';
import { createProduct, updateProduct, deleteProduct } from 'src/actions/productActions';
import { deleteWorkitem } from 'src/actions/workitemActions';
// import { THEMES } from 'src/constants';

const initialWorkOrderState = {
    isInitialised: false,
    // workOrder: null
};

const reducer = (state, action) => {
    switch (action.type) {
        case 'INITIALISE': {
            const { data } = action.payload;

            return {
                ...state,
                isInitialised: true,
                workOrder: data
            };
        }

        case 'UPDATE_WORK_ORDER': {
            const { new_work_order } = action.payload;

            return {
                ...state,
                workOrder: new_work_order
            };
        }

        case 'ADD_TIME_ENTRY': {
            const { new_time_entry } = action.payload;

            return {
                ...state,
                workOrder: {
                    ...state.workOrder,
                    time_entries: [
                        ...state.workOrder.time_entries,
                        new_time_entry
                    ]
                }
            };
        }

        case 'UPDATE_TIME_ENTRY': {
            const { new_time_entry } = action.payload;

            return {
                ...state,
                workOrder: {
                    ...state.workOrder,
                    time_entries: [
                        ...state.workOrder.time_entries.filter(entry => entry.id != new_time_entry.id),
                        new_time_entry
                    ]
                }
            };
        }

        case 'REMOVE_TIME_ENTRY': {
            const { removed_time_entry } = action.payload;

            return {
                ...state,
                workOrder: {
                    ...state.workOrder,
                    time_entries: [
                        ...state.workOrder.time_entries.filter(entry => entry.id !== removed_time_entry.id)
                    ]
                }
            };
        }

        case 'ADD_PRODUCT': {
            const { new_product } = action.payload;

            return {
                ...state,
                workOrder: {
                    ...state.workOrder,
                    products: [
                        ...state.workOrder.products,
                        new_product
                    ]
                }
            };
        }

        case 'EDIT_PRODUCT': {
            const { updated_product_id, updated_product_values } = action.payload;
            console.log({
                ...state.workOrder.products.filter(product => product.id == updated_product_id)[0],
                ...updated_product_values
            })
            return {
                ...state,
                workOrder: {
                    ...state.workOrder,
                    products: [
                        ...state.workOrder.products.map(product => {
                            if (product.id != updated_product_id) {
                                return product
                            } else {
                                return {
                                    ...product,
                                    ...updated_product_values
                                }
                            }
                        })
                    ]
                }
            };
        }

        case 'REMOVE_PRODUCT': {
            const { removed_product } = action.payload;

            return {
                ...state,
                workOrder: {
                    ...state.workOrder,
                    products: [
                        ...state.workOrder.products.filter(product => product.id !== removed_product.id)
                    ]
                }
            };
        }

        case 'REMOVE_WORK_ITEM': {
            const { removed_work_item } = action.payload;

            return {
                ...state,
                workOrder: {
                    ...state.workOrder,
                    workitems: [
                        ...state.workOrder.workitems.filter(workitem => workitem.id !== removed_work_item.id)
                    ]
                }
            };
        }

        default: {
            return { ...state };
        }
    }
};

const WorkOrderContext = createContext({
    ...initialWorkOrderState
});

export const WorkOrderProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialWorkOrderState);
    const location = useLocation();
    const workOrderId = location.pathname.split('/').slice(-1).pop()

    const editWorkOrder = async (id, values) => {
        try {
            const response = await updateWorkorder(id, values)
                .then(response => {
                    if (response.id == id) {
                        return response;
                    } else if (response.status === 400) {
                        throw new Error('Error updating work order');
                    } else {
                        throw new Error('Internal server error');
                    }
                })
                .catch(err => console.log(err));
            dispatch({
                type: 'UPDATE_WORK_ORDER',
                payload: {
                    new_work_order: response
                }
            });

            return response
        } catch (err) {
            console.error(err);
        }
    }

    const addTimeEntry = async (values) => {
        try {
            const response = await createTimeEntry(values)
                .then(response => {
                    if (response.id) {
                        dispatch({
                            type: 'ADD_TIME_ENTRY',
                            payload: {
                                new_time_entry: response
                            }
                        });
                        return response;
                    } else if (response.status === 400) {
                        throw new Error('Error creating time entry');
                    } else {
                        throw new Error('Internal server error');
                    }
                })
                .catch(err => console.log(err));

            console.log(response)

            return response
        } catch (err) {
            console.error(err);
        }
    }

    const editTimeEntry = async (values) => {
        try {
            const response = await updateTimeEntry(values.id, values)
                .then(response => {
                    if (response.id == values.id) {
                        return response;
                    } else if (response.status === 400) {
                        throw new Error('Error updating time entry');
                    } else {
                        throw new Error('Internal server error');
                    }
                })
                .catch(err => console.log(err));

            console.log(response)

            dispatch({
                type: 'UPDATE_TIME_ENTRY',
                payload: {
                    new_time_entry: response
                }
            });

            return response
        } catch (err) {
            console.error(err);
        }
    }

    const removeTimeEntry = async (values) => {
        try {

            dispatch({
                type: 'REMOVE_TIME_ENTRY',
                payload: {
                    removed_time_entry: values
                }
            });

            const response = await deleteTimeEntry(values.id)
                .then(response => {
                    if (response.id) {
                        return response;
                    } else if (response.status === 400) {
                        throw new Error('Error deleting time entry');
                    } else {
                        throw new Error('Internal server error');
                    }
                })
                .catch(err => console.log(err));

            console.log(response)

            return response
        } catch (err) {
            console.error(err);
        }
    }

    const addProduct = async (values) => {
        try {
            const response = await createProduct(values)
                .then(response => {
                    if (response.id) {
                        return response;
                    } else if (response.status === 400) {
                        throw new Error('Error creating product');
                    } else {
                        throw new Error('Internal server error');
                    }
                })
                .catch(err => console.log(err));

            console.log(response)
            if (response) {
                dispatch({
                    type: 'ADD_PRODUCT',
                    payload: {
                        new_product: response
                    }
                });

                return response
            } else {
                return { error: true, message: 'Something went wrong' }
            }
        } catch (err) {
            console.error(err);
        }
    }

    const editProduct = async (id, values) => {
        try {
            console.log(id, values)
            dispatch({
                type: 'EDIT_PRODUCT',
                payload: {
                    updated_product_id: id,
                    updated_product_values: values
                }
            });

            const response = await updateProduct(id, values)
                .then(response => {
                    if (response.id == id) {
                        return response;
                    } else if (response.status === 400) {
                        throw new Error('Error updating product');
                    } else {
                        throw new Error('Internal server error');
                    }
                })
                .catch(err => console.log(err));

            console.log(response)



            return response
        } catch (err) {
            console.error(err);
        }
    }

    const removeProduct = async (values) => {
        try {

            dispatch({
                type: 'REMOVE_PRODUCT',
                payload: {
                    removed_product: values
                }
            });

            const response = await deleteProduct(values.id)
                .then(response => {
                    if (response.id) {
                        return response;
                    } else if (response.status === 400) {
                        throw new Error('Error deleting product');
                    } else {
                        throw new Error('Internal server error');
                    }
                })
                .catch(err => console.log(err));

            console.log(response)


            return response
        } catch (err) {
            console.error(err);
        }
    }

    const removeWorkItem = async (values) => {
        try {
            console.log(values)

            dispatch({
                type: 'REMOVE_WORK_ITEM',
                payload: {
                    removed_work_item: values
                }
            });

            const response = await deleteWorkitem(values.id)
                .then(response => {
                    if (response.id) {
                        return response;
                    } else if (response.status === 400) {
                        throw new Error('Error deleting work item');
                    } else {
                        throw new Error('Internal server error');
                    }
                })
                .catch(err => console.log(err));

            console.log(response)


            return response
        } catch (err) {
            console.error(err);
        }
    }

    useEffect(() => {
        const initialise = async () => {
            try {
                const response = await fetchAWorkOrder(workOrderId)
                    .then(response => {
                        if (response.id == workOrderId) {
                            return response;
                        } else if (response.status === 400) {
                            throw new Error('Error loading work order');
                        } else {
                            throw new Error('Internal server error');
                        }
                    })
                    .catch(err => console.log(err));

                if (!response)
                    return { status: 400, message: 'Error loading work order' };

                console.log(response)
                dispatch({
                    type: 'INITIALISE',
                    payload: {
                        data: response
                    }
                });

            } catch (err) {
                console.error(err);
            }
        };

        initialise();
    }, []);

    if (!state.isInitialised) {
        return <SplashScreen />;
    }

    return (
        <WorkOrderContext.Provider
            value={{
                ...state,
                editWorkOrder,
                addTimeEntry,
                editTimeEntry,
                removeTimeEntry,
                addProduct,
                editProduct,
                removeProduct,
                removeWorkItem
            }}
        >
            {children}
        </WorkOrderContext.Provider>
    );
};

export const WorkOrderConsumer = WorkOrderContext.Consumer;

export default WorkOrderContext;