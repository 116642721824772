import React, { createContext, useEffect, useReducer } from 'react';
import jwtDecode from 'jwt-decode';
import SplashScreen from 'src/components/SplashScreen';
import { fetchUsers } from 'src/actions/userActions';
import { Redirect } from 'react-router-dom';
import qs from 'qs';

const nodeURL = process.env.REACT_APP_STRAPI_URL

const initialAuthState = {
  isAuthenticated: false,
  isInitialised: false,
  user: null,
  impersonating: false
};

const isValidToken = async accessToken => {
  if (!accessToken) {
    return false;
  }

  const decoded = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;

  if (decoded.exp <= currentTime) {
    return false // token is expired
  };

  const response = await fetchUsers();
  if (response.statusCode == 401) {
    console.log(response)
    return false
  }

  return true
};

const setSession = ({ accessToken, user, impersonating = false }) => {
  if (accessToken && user) {
    if (impersonating) {
      // Store admin user data and jwt in secure store
      localStorage.setItem('impersonatorJWT', localStorage.getItem('accessToken'));
      localStorage.setItem('impersonatorUser', localStorage.getItem('user'));
    } else {
      // Delete impersonator data from secure store
      localStorage.removeItem('impersonatorJWT');
      localStorage.removeItem('impersonatorUser');
    }

    localStorage.setItem('accessToken', accessToken);
    localStorage.setItem('user', JSON.stringify(user));
    localStorage.setItem('impersonating', impersonating);
  } else {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('jwt'); // for compatibility
    localStorage.removeItem('user');
  }
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'INITIALISE': {
      const { isAuthenticated, user, impersonating = false } = action.payload;

      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        user,
        impersonating
      };
    }
    case 'LOGIN': {
      const { user, impersonating = false } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user,
        impersonating
      };
    }
    case 'LOGOUT': {
      return {
        ...state,
        isAuthenticated: false,
        user: null
      };
    }
    case 'REGISTER': {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user
      };
    }
    case 'UPDATE_PROFILE': {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user
      };
    }
    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext({
  ...initialAuthState,
  method: 'JWT',
  login: () => Promise.resolve(),
  loginMsft: () => Promise.resolve(),
  logout: () => { },
  register: () => Promise.resolve(),
  updateProfile: () => Promise.resolve(),
  impersonateUser: () => Promise.resolve(),
  stopImpersonating: () => Promise.resolve()
});

const filterUserData = (userData) => {
  // Create filtered user excluding extranious data that is too large to store in secure store
  const { clients, time_entries, scheduleitems, workorders, badges, ...filteredUser } = userData
  return filteredUser
}

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);

  const impersonateUser = async (userId) => {
    const response = await fetch(`${nodeURL}/users/impersonate/${userId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
      }
    });
    const data = await response.json();
    console.log(data)
    if (data && data.jwt) {
      const { jwt, user } = data;
      setSession({ accessToken: jwt, user, impersonating: true });
      dispatch({
        type: 'LOGIN',
        payload: {
          user: filterUserData(user),
          impersonating: true
        }
      });
    }
  }

  const stopImpersonating = async () => {
    dispatch({
      type: 'LOGIN',
      payload: {
        user: JSON.parse(localStorage.getItem('impersonatorUser')),
        impersonating: false
      }
    })
    setSession({
      accessToken: localStorage.getItem('impersonatorJWT'),
      user: JSON.parse(localStorage.getItem('impersonatorUser')),
      impersonating: false
    });
    // refresh page to update data after end of impersonating session
    setTimeout(() => {
      window.location.reload();
    }, 100)
  }

  const loginMsft = async (msftQueryString, isMobile = false) => {
    console.log(msftQueryString)

    // parse the query string
    const parsedQueryString = qs.parse(msftQueryString)
    console.log(parsedQueryString)

    const opts = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    };

    const response = await fetch(`${nodeURL}/auth/microsoft/callback${msftQueryString}`, opts)

    if (response && response.status === 400) {
      const responseJson = await response.json();
      return { status: 400, message: responseJson.message[0].messages[0].message }
    }


    const responseJson = await response.json();

    const { jwt, user } = responseJson;

    if (isMobile) {

      console.log("User Agent: ", navigator.userAgent)

      // detect if this is an android device
      const isAndroid = /Android/i.test(navigator.userAgent);
      const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);

      if (isAndroid) {
        // redirect to expo app Android URL
        return window.location.href = `exp://u.expo.dev/a2f74b6c-8c58-4171-8d20-2658727524b8?access_token=${jwt}&runtime-version=1.0.0&channel-name=production&platform=android`
      } else {
        // redirect to expo app iOS URL
        return window.location.href = `exp://u.expo.dev/a2f74b6c-8c58-4171-8d20-2658727524b8?access_token=${jwt}&runtime-version=1.0.0&channel-name=production&platform=ios`
      }
    }

    setSession({
      accessToken: jwt,
      user: user
    });
    dispatch({
      type: 'LOGIN',
      payload: {
        user
      }
    });
  }

  const login = async (email, password) => {
    const body = {
      identifier: email,
      password: password
    };

    const opts = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    };

    const response = await fetch(`${nodeURL}/auth/local`, opts)

    if (response && response.status === 400) {
      const responseJson = await response.json();
      return { status: 400, message: responseJson.message[0].messages[0].message }
    }

    const responseJson = await response.json();

    const { jwt, user } = responseJson;

    setSession({
      accessToken: jwt,
      user: user
    });
    dispatch({
      type: 'LOGIN',
      payload: {
        user
      }
    });
  };

  const logout = () => {
    setSession({
      accessToken: null,
      user: null
    });
    dispatch({ type: 'LOGOUT' });
    return true;
  };

  const register = async (email, name, password) => {
    const body = {
      email: email,
      name: name,
      password: password,
      username: email
    };

    const opts = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    };

    const response = await fetch(
      `${nodeURL}/auth/local/register`,
      opts
    ).then(data => data.json());

    const { jwt, user } = response;

    setSession({
      accessToken: jwt,
      user: user
    });
    dispatch({
      type: 'REGISTER',
      payload: {
        user
      }
    });
  };

  const updateProfile = async (id, body) => {
    const opts = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
      },
      body: JSON.stringify(body)
    };

    const user = await fetch(`${nodeURL}/users/${id}`, opts).then(data =>
      data.json()
    );

    setSession({
      accessToken: localStorage.getItem('accessToken'),
      user: user
    });
    dispatch({
      type: 'UPDATE_PROFILE',
      payload: {
        user
      }
    });

    return user;
  };

  useEffect(() => {
    const initialise = async () => {
      try {
        const accessToken = window.localStorage.getItem('accessToken');
        const user = JSON.parse(window.localStorage.getItem('user'));
        const impersonating = window.localStorage.getItem('impersonating') == 'true' ? true : false;

        if (accessToken && await isValidToken(accessToken) && user) {
          setSession({
            accessToken: accessToken,
            user: user,
            impersonating: impersonating
          });

          dispatch({
            type: 'INITIALISE',
            payload: {
              isAuthenticated: true,
              user,
              impersonating
            }
          });
        } else {
          dispatch({
            type: 'INITIALISE',
            payload: {
              isAuthenticated: false,
              user: null,
              accessToken: null
            }
          });
          logout();
          return <Redirect to="/login" />;
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALISE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    };

    initialise();
  }, []);

  if (!state.isInitialised) {
    return <SplashScreen />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'JWT',
        login,
        loginMsft,
        logout,
        register,
        updateProfile,
        impersonateUser,
        stopImpersonating
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
