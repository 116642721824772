import React, { useState } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Button,
  ListItem,
  Tooltip,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  item: {
    display: 'block',
    paddingTop: 0,
    paddingBottom: 0
  },
  itemLeaf: {
    display: 'flex',
    paddingTop: 10,
    paddingBottom: 10
  },
  button: {
    color: theme.palette.text.secondary,
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%'
  },
  buttonLeaf: {
    color: theme.palette.text.secondary,
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightRegular,
    '&.depth-0': {
      '& $title': {
        fontWeight: theme.typography.fontWeightBold
      }
    }
  },
  icon: {
    color: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
    marginLeft: 12
  },
  title: {
    color: theme.palette.text.navbar,
    marginRight: 'auto',
    marginLeft: '30px',
    fontWeight: '600'
  },
  active: {
    color: theme.palette.text.navbar,
    backgroundColor: theme.palette.abeGreen.light,
    '& $title': {
      fontWeight: theme.typography.fontWeightMedium
    },
    '& $icon': {
      color: theme.palette.text.navbar
    }
  }
}));

const NavItem = ({
  children,
  className,
  depth,
  href,
  icon: Icon,
  info: Info,
  open: openProp,
  title,
  onClick,
  showLabels,
  ...rest
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(openProp);

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  let paddingLeft = 8;

  if (depth > 0) {
    paddingLeft = 32 + 8 * depth;
  }

  const style = { paddingLeft };

  return (
    <ListItem
      className={clsx(classes.itemLeaf, className)}
      disableGutters
      key={title}
      {...rest}
    >
      {!showLabels ? (
        <Tooltip title={title} placement="right">
          <Button
            activeClassName={classes.active}
            className={clsx(classes.buttonLeaf, `depth-${depth}`)}
            component={RouterLink}
            exact
            style={style}
            to={href}
            target={href == '/bug-report' && "_blank"}
            onClick={onClick}
          >
            {Icon && <Icon className={classes.icon} size="20" />}
            {showLabels && (<span className={classes.title}>{title}</span>)}
            {Info && <Info />}
          </Button>
        </Tooltip>) : (<Button
          activeClassName={classes.active}
          className={clsx(classes.buttonLeaf, `depth-${depth}`)}
          component={RouterLink}
          exact
          style={style}
          to={href}
          target={href == '/bug-report' ? "_blank" : ""}
          onClick={onClick}
        >
          {Icon && <Icon className={classes.icon} size="20" />}
          {showLabels && (<span className={classes.title}>{title}</span>)}
          {Info && <Info />}
        </Button>)}
    </ListItem>
  );
};

NavItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  depth: PropTypes.number,
  href: PropTypes.string,
  icon: PropTypes.elementType,
  info: PropTypes.elementType,
  open: PropTypes.bool,
  title: PropTypes.string.isRequired
};

NavItem.defaultProps = {
  open: false
};

export default NavItem;
