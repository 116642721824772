const nodeURL = process.env.REACT_APP_STRAPI_URL
const headers = () => ({
  "Content-Type": "application/json",
  Authorization: `Bearer ${localStorage.getItem("accessToken")}`
})

// ********** CREATE ROOM  ********** //
export const createRoom = async (details) => {
  const opts = {
    method: 'POST',
    headers: headers(),
    body: JSON.stringify(details),
  }

  const response = await fetch(`${nodeURL}/rooms`, opts);
  const data = response.json();

  return data;
}

// ********** GET ALL ROOMS  ********** //
export const fetchRooms = async () => {
  const opts = {
    method: 'GET',
    headers: headers(),
  }

  const response = await fetch(`${nodeURL}/rooms`, opts);
  const data = response.json();

  return data;
}


// ********** GET ROOM OPTIONS (for autocomplete menu)  ********** //
export const fetchRoomOptions = async () => {
  const opts = {
    method: 'GET',
    headers: headers()
  }

  const response = await fetch(`${nodeURL}/roomoptions`, opts);
  const data = response.json();

  return data;
}


// ********** GET A ROOM (BY ID)  ********** //
export const fetchRoom = async (id) => {
  const opts = {
    method: 'GET',
    headers: headers(),
  }

  const response = await fetch(`${nodeURL}/rooms/${id}`, opts);
  const data = response.json();
  return data;
}

// ********** UPDATE A ROOM (BY ID)  ********** //
export const updateRoom = async (id, details) => {
  const opts = {
    method: 'PUT',
    headers: headers(),
    body: JSON.stringify(details)
  }

  const response = await fetch(`${nodeURL}/rooms/${id}`, opts);
  const data = response.json();

  return data;
}

// ********** DELETE A ROOM (BY ID)  ********** //
export const deleteRoom = async (id) => {
  const opts = {
    method: 'DELETE',
    headers: headers(),
  }

  const response = await fetch(`${nodeURL}/rooms/${id}`, opts);
  const data = response.json();

  return data;
}