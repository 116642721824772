import React from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '../hooks/useAuth';

const DevGuard = ({ children }) => {
  const { isAuthenticated, user } = useAuth();
  // console.log('DevGuard: user: ', user);
  if (isAuthenticated && user.role.name != 'Project Manager' && user.role.name != 'Administrator' && user.role.name != 'Sales' && user.role.name != 'Scheduler' && user.role.name != 'Warehouse Manager' || user.role.name == 'Technician') {
    return <Redirect to="/app/dashboard" />;
  }

  return <>{children}</>;
};

DevGuard.propTypes = {
  children: PropTypes.node
};

export default DevGuard;
