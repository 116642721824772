import { Toolbar, AppBar, Typography, Button } from "@material-ui/core";
import useAuth from "src/hooks/useAuth";
import React from "react";
const ImpersonatingAppBar = () => {
    const { stopImpersonating, user } = useAuth();

    return (
        <AppBar position="static">
            <Toolbar>
                <Typography variant="h6" color="inherit">
                    Impersonation Mode
                </Typography>
                <Button color="inherit" onClick={stopImpersonating}>
                    Stop Impersonating
                </Button>
            </Toolbar>
        </AppBar>
    );
}

export default ImpersonatingAppBar;